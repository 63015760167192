import React from "react"
import Layout from "../../components/layout"
import { Container } from "reactstrap"
import Head from "../../components/head"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import catImage from "../../images/category/101.jpg"
import catNum1Image from "../../images/dummies/slappybasic.jpg"
import catNum2Image from "../../images/dummies/slappyu1.jpg"
import catNum3Image from "../../images/dummies/slappyu2.jpg"
import catNum4Image from "../../images/dummies/slappyu2nhb.jpg"
import catNum5Image from "../../images/dummies/slappyu2a.jpg"
import catNum6Image from "../../images/dummies/slappyu3.jpg"
import catNum7Image from "../../images/dummies/slappyu3a.jpg"
// import catNum8Image from "../../images/category/87.jpg"
// import catNum9Image from "../../images/category/69.jpg"
// import catNum10Image from "../../images/category/58.jpg"
// import catNum11Image from "../../images/category/70.jpg"
// import catNum12Image from "../../images/category/77.jpg"
// import catNum13Image from "../../images/category/80.jpg"
// import catNum14Image from "../../images/category/83.jpg"
// import catNum15Image from "../../images/category/81.jpg"
// import catNum16Image from "../../images/category/79.jpg"
// import catNum17Image from "../../images/category/86.jpg"

const Slappy = () => {
  //   const CatImage = "../images/category/101.jpg"
  const CatName = "BUY YOUR OWN SLAPPY FROM GOOSEBUMPS VENTRILOQUIST DUMMY TODAY!"

  const CatTagLine = "Slappy from Goosebumps dummies are available in 7 different versions!"

  const CatDesc =
    "Adults sometimes find Slappy From Goosebumps to be a scary - even evil looking - ventriloquist dummy doll but kids love him! " +
    "Star of R.L. Stine's Goosebumps series, Slappy the dummy has appeared in many books including the three Night Of The Living Dummy " +
    "books and Goosebumps HorrorLand: Revenge Of The Living Dummy. Slappy was seen on the Goosebumps television show. One of our most" +
    "popular ventriloquist dummies, you can buy your own Slappy From Goosebumps ventriloquist dummy today!" +
    "ThrowThings.com offers eight versions of Slappy From Goosebumps dummies. We have the Basic Slappy Ventriloquist Doll, the Standard " +
    "Upgrade Slappy Ventriloquist Dummy, three versions of the Deluxe Upgrade Slappy Ventriloquist Dummy (one with black hair, one with " +
    "auburn hair, and one with molded hair), and two versions of the the Super Deluxe Upgrade Slappy Ventriloquist Dummy (one with black " +
    "hair, one with auburn hair, and one with molded hair).Click Here to see which dummies are in stock and ready to ship. " +
    "Before deciding on which Slappy From Goosebumps ventriloquist dummy doll to purchase we suggest that you watch the short video at " +
    "the bottom of this page to see the differences between the various levels of ventriloquist dummy dolls for sale and what makes our " +
    "figures so special."

  const categories = [
    {
      catNum: 1,
      categoryName: "Slappy Ventriloquist Dummy Doll",
      categoryTag: "Stuffed Body, Pull String To Make Mouth Move $64.99",
      categoryImage: catNum1Image,
      categoryLink: "/products/slappy-from-goosebumps-basic-ventriloquist-dummy",
    },
    {
      catNum: 2,
      categoryName: "Slappy Standard Upgrade Ventriloquist Dummy",
      categoryTag: "Moving Head, Trigger Action Mouth & More $174.99",
      categoryImage: catNum2Image,
      categoryLink: "/products/slappy-from-goosebumps-standard-upgrade-dummy",
    },
    {
      catNum: 3,
      categoryName: "Slappy Deluxe Upgrade Ventriloqust Dummy",
      categoryTag: "Moving Eyes & Head, Lifelike Wig, More $294.99",
      categoryImage: catNum3Image,
      categoryLink: "/products/slappy-from-goosebumps-deluxe-upgrade-dummy",
    },
    {
      catNum: 4,
      categoryName: "Slappy Special Deluxe Upgrade Ventriloqust Dummy",
      categoryTag: "Moving Eyes & Head, Molded Hair, More $299.99",
      categoryImage: catNum4Image,
      categoryLink: "/products/slappy-special-deluxe-upgrade-dummy",
    },
    {
      catNum: 5,
      categoryName: "Slappy Auburn Deluxe Upgrade Ventriloqust Dummy",
      categoryTag: "Moving Eyes & Head, Lifelike Wig, More $299.99",
      categoryImage: catNum5Image,
      categoryLink: "/products/auburn-slappy-deluxe-upgrade-dummy",
    },
    {
      catNum: 6,
      categoryName: "Slappy Super Deluxe Upgrade Ventriloqust Dummy",
      categoryTag: "Moving Eyes and Head PLUS Moving Brows! $384.99",
      categoryImage: catNum6Image,
      categoryLink: "/products/slappy-from-goosebumps-super-deluxe-upgrade-dummy",
    },
    {
      catNum: 7,
      categoryName: "Slappy Auburn Super Deluxe Upgrade Ventriloquist Dummy",
      categoryTag: "Moving Eyes and Head PLUS Moving Brows! $384.99",
      categoryImage: catNum7Image,
      categoryLink: "/products/auburn-slappy-super-deluxe-upgrade-dummy",
    },
  ]
  return (
    <Layout>
      <Head
        title="Slappy From Goosebumps Ventriloquist Dummies"
        description="Slappy From Goosebumps ventriloquist dummies are available for sale from ThrowThings.com in 7 different versions."
      />
      <Container>
        {/* <!-- section start --> */}
        <section className="section-b-space ratio_asos">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top-banner-wrapper">
                          {/* <Link to="#"> */}
                          <img
                            src={catImage}
                            className="img-fluid lazyload"
                            alt={CatTagLine}
                          />
                          {/* </Link> */}
                          {/* <Link to="#"><img src={require("../assets/images/mega-menu/2.jpg")} className="img-fluid blur-up lazyload" alt=""/></Link> */}
                          <div className="top-banner-content small-section">
                            <h4>{CatName}</h4>
                            <h5>{CatTagLine}</h5>
                            <p>{CatDesc}</p>
                          </div>
                        </div>
                        <div className="collection-product-wrapper">
                          <div className="product-wrapper-grid">
                            <div className="row">
                              {categories.map(category => (
                                <div
                                  key={category.catNum}
                                  className="col-xl-3 col-md-6 col-grid-box"
                                >
                                  <div className="product-box">
                                    <div className="img-wrapper">
                                      <div className="front">
                                        <Link to={category.categoryLink}>
                                          <LazyLoad>
                                            <img
                                              src={category.categoryImage}
                                              className="img-fluid lazyload bg-img"
                                              alt={category.categoryName}
                                            />
                                          </LazyLoad>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product-detail">
                                      <div>
                                        <Link to={category.categoryLink}>
                                          <h4>{category.categoryName}</h4>
                                          <h6>{category.categoryTag}</h6>
                                          <button class="btn btn-solid">More Information</button>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <section>
                          <h5>ThrowThings.com is your number one store to buy ventriloquist dummies. We have many different characters and levels in stock and ready to ship including Slappy dummies. To see the differences between the various levels of ventriloquist dolls for sale and what makes our figures so special please watch the following short video. (Just click on the arrow.)</h5>
                          <section className="text-center">
                            <iframe title="An Introduction To Our Ventriloquist Dummies" width="320" height="265" src="https://www.youtube-nocookie.com/embed/33u9yc72gYE?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section End --> */}
      </Container>
    </Layout>
  )
}

export default Slappy